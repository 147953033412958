import LoadServerState from './LoadServerState';
export default {
  mounted()
  {
    LoadServerState({
      route: this.$route,
      store: this.$store,
      stateKind: this.$options?.pollStateKind ?? 'status',
      onlyIfFirstLoad: this.$options?.onlyIfFirstLoad ?? false
    });
  },
};
