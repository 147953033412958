//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//TODO remember me
import BusinessLayer from "~/plugins/businessLayer";
import LoadServerStateMixin from '~/mixins/LoadServerStateMixin';
export default {
  mixins: [LoadServerStateMixin],
  head() {
    return {
      title: this.$t("page_login." + (this.isRegister ? "register" : "login")),
    };
  },
  async asyncData({ params }) {
    const isRegister = params.login == "register";
    return { isRegister };
  },
  computed: {
    development() {
      return process.env.NODE_ENV !== "production";
    },
    isSomebodyLoggedIn() {
      var result = this.$store.getters["projectState/isLogged"];
      if (result != this.wasLoggedIn) {
        this.fetching = true; //Hide the progress bar
      }
      this.wasLoggedIn = result;
      return result;
    },
  },
  data() {
    return {
      wasLoggedIn: false,
      fetching: false,
    };
  },
  methods: {
    login(event) {
      this.fetching = true;
      this.$store.dispatch("projectState/login", new FormData(event.target)).finally(() => this.fetching = false);
    },
    register(event) {
      //Check for passwords match
      const formData = new FormData(event.target);
      if (formData.get("pwd") != formData.get("repeat")) {
        BusinessLayer.onError(this.$t("page_login.password_doesnt_match"));
        return;
      }

      this.fetching = true;
      this.$store.dispatch("projectState/register", formData).finally(() => this.fetching = false);
    },
  },
};
