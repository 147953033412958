const ignoredRoutes = ['showDrawer', 'login', 'thank-you', 'why', 'who'];
var loadedSomething = false;

export default function ({ route, store, stateKind, onlyIfFirstLoad })
{
  if (!onlyIfFirstLoad || !loadedSomething)
  {
    if (ignoredRoutes.findIndex((entry) => route.path.indexOf(entry) != -1) != -1)
    {
      //current route is on the blacklist
      return;
    }
    store.dispatch('projectState/downloadServerState', stateKind ?? 'status');
    loadedSomething = true;
  }
}
